body { background-color: #ffffff; }
body { color: var(--clr-9551); }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
color: var(--clr-9551);
font-family: 'Oxygen';
font-weight: 700;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 25px;

@media #{$medium-up} {
font-size: 30px;

}
@media #{$large-up} {
font-size: 35px;

}
}
h2 {
color: var(--clr-9551);
font-family: 'Oxygen';
font-weight: 700;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 22px;

@media #{$medium-up} {
font-size: 25px;

}
@media #{$large-up} {
font-size: 30px;

}
}
h3 {
color: var(--clr-9551);
font-family: 'Oxygen';
font-weight: 700;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 20px;

@media #{$large-up} {
font-size: 22px;

}
}
h4 {
color: var(--clr-9551);
font-family: 'Oxygen';
font-weight: 700;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 18px;

@media #{$large-up} {
font-size: 25px;

}
}
h5 {
color: var(--clr-9551);
font-family: 'Oxygen';
font-weight: 700;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 17px;

@media #{$large-up} {
font-size: 20px;

}
}
h6 {
color: var(--clr-9551);
font-family: 'Oxygen';
font-weight: normal;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 16px;

}
.button {
font-family: 'Oxygen';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-body, .me-HtmlText blockquote {
font-family: 'Oxygen';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-author {
font-family: 'Oxygen';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul {
color: var(--clr-9552);
font-family: 'Oxygen';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 12px;

@media #{$medium-up} {
font-size: 14px;

}
}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
font-family: 'Oxygen';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
summary {
font-family: 'Oxygen';
font-weight: normal;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 18px;

@media #{$large-up} {
font-size: 25px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Oxygen';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Oxygen';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Oxygen';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Oxygen';
font-weight: normal;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 24px;

}
body {
font-family: 'Oxygen';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
p { padding-bottom: 1em; }
a {color: var(--clr-9552);}
a:hover {color: var(--clr-9553);}
/* Primary:2 */
.MES2 {
background-color: var(--clr-9551);
color: #ffffff;
 }
/* Primary:3 */
.MES3 {
background-color: var(--clr-9551);
color: #ffffff;
 }
.MES3 {
background-color: var(--clr-9551);
color: #ffffff;
h1.MEC3, h2.MEC3, h3.MEC3, h4.MEC3, h5.MEC3, h6.MEC3 { color: #ffffff;
 }
 }
a.MEC3 { color: var(--clr-9554);
&:hover { color: #ffffff;}
 }
cite.MEC3{
color: #ffffff;
}
/* Secondary:4 */
.MES4 {
background-color: var(--clr-9552);
&:hover {background-color: var(--clr-9554);}
color: #ffffff;
 }
/* Secondary:5 */
.MES5 {
background-color: var(--clr-9552);
color: #ffffff;
 }
.MES5 {
background-color: var(--clr-9552);
color: #ffffff;
h1.MEC5, h2.MEC5, h3.MEC5, h4.MEC5, h5.MEC5, h6.MEC5 { color: #ffffff;
 }
 }
cite.MEC5{
color: #ffffff;
}
/* Alternate:6 */
.MES6 {
background-color: var(--clr-9555);
color: var(--clr-9553);
 }
/* Alternate:7 */
.MES7 {
background-color: var(--clr-9555);
color: var(--clr-9553);
 }
.MES7 {
background-color: var(--clr-9555);
color: var(--clr-9553);
h1.MEC7, h2.MEC7, h3.MEC7, h4.MEC7, h5.MEC7, h6.MEC7 { color: var(--clr-9553);
 }
 }
cite.MEC7{
color: var(--clr-9553);
}
/* Light:8 */
.MES8 {
background-color: var(--clr-9556);
color: var(--clr-9553);
 }
/* Light:9 */
.MES9 {
background-color: var(--clr-9556);
color: var(--clr-9553);
 }
.MES9 {
background-color: var(--clr-9556);
color: var(--clr-9553);
h1.MEC9, h2.MEC9, h3.MEC9, h4.MEC9, h5.MEC9, h6.MEC9 { color: var(--clr-9553);
 }
 }
cite.MEC9{
color: var(--clr-9553);
}
/* Dark:10 */
.MES10 {
background-color: var(--clr-9553);
color: #ffffff;
&:hover { color: var(--clr-9559);}
 }
/* Dark:11 */
.MES11 {
background-color: var(--clr-9553);
color: #ffffff;
 }
.MES11 {
background-color: var(--clr-9553);
color: #ffffff;
h1.MEC11, h2.MEC11, h3.MEC11, h4.MEC11, h5.MEC11, h6.MEC11 { color: #ffffff;
 }
 }
a.MEC11 { color: var(--clr-9554);
&:hover { color: #ffffff;}
 }
cite.MEC11{
color: #ffffff;
}
/* Shade 1:12 */
.MES12 {
background-color: var(--clr-9557);
color: #ffffff;
 }
/* Shade 1:13 */
.MES13 {
background-color: var(--clr-9557);
color: #ffffff;
 }
.MES13 {
background-color: var(--clr-9557);
color: #ffffff;
h1.MEC13, h2.MEC13, h3.MEC13, h4.MEC13, h5.MEC13, h6.MEC13 { color: #ffffff;
 }
 }
a.MEC13 { color: #ffffff;
&:hover { color: var(--clr-9558);}
 }
cite.MEC13{
color: #ffffff;
}
/* Shade 2:14 */
.MES14 {
background-color: var(--clr-9554);
color: #ffffff;
 }
/* Shade 2:15 */
.MES15 {
background-color: var(--clr-9554);
color: #ffffff;
 }
.MES15 {
background-color: var(--clr-9554);
color: #ffffff;
h1.MEC15, h2.MEC15, h3.MEC15, h4.MEC15, h5.MEC15, h6.MEC15 { color: #ffffff;
 }
 }
cite.MEC15{
color: #ffffff;
}
/* Shade 3:16 */
.MES16 {
background-color: var(--clr-9558);
color: var(--clr-9553);
 }
/* Shade 3:17 */
.MES17 {
background-color: var(--clr-9558);
color: var(--clr-9553);
font-size: 19.6px;
@media #{$medium-up} {
font-size: 24px;
};
@media #{$large-up} {
font-size: 22.4px;
};
padding: 10px 0;

@media #{$large-up} {
padding: 5px 0;

}
 }
.MES17 {
background-color: var(--clr-9558);
color: var(--clr-9553);
font-size: 19.6px;
@media #{$medium-up} {
font-size: 24px;
};
@media #{$large-up} {
font-size: 22.4px;
};
padding: 10px 0;

@media #{$large-up} {
padding: 5px 0;

}
h1.MEC17, h2.MEC17, h3.MEC17, h4.MEC17, h5.MEC17, h6.MEC17 { color: var(--clr-9553);
 }
 }
a.MEC17 { color: var(--clr-9552);
&:hover { color: var(--clr-9553);}
 }
cite.MEC17{
color: var(--clr-9553);
font-size: 19.6px;
@media #{$medium-up} {
font-size: 24px;
};
@media #{$large-up} {
font-size: 22.4px;
};
}
/* Keep me in the loop:18 */
.MES18 {
background-color: var(--clr-9553);
& > .underlay, & > .inner-overlay { opacity: 0.5 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://roadandtrack.com.au/img/14/18');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}min-height:300px;
@media #{$medium-up} {
min-height: 300px;};
@media #{$large-up} {
min-height: 485px;};
 }
.MES18 {
background-color: var(--clr-9553);
& > .underlay, & > .inner-overlay { opacity: 0.5 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://roadandtrack.com.au/img/14/18');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}min-height:300px;
@media #{$medium-up} {
min-height: 300px;};
@media #{$large-up} {
min-height: 485px;};
h1.MEC18, h2.MEC18, h3.MEC18, h4.MEC18, h5.MEC18, h6.MEC18 { color: var(--clr-9555);
 }
 }
/* home text block:19 */
.MES19 {
background-color: var(--clr-9556);
@media #{$large-up} {
min-height: 485px;};
padding: 0 40px;

 }
.MES19 {
background-color: var(--clr-9556);
@media #{$large-up} {
min-height: 485px;};
padding: 0 40px;

 }
/* Auto Atelier:20 */
.MES20 {
background-color: var(--clr-9553);
& > .underlay, & > .inner-overlay { opacity: 0.5 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://roadandtrack.com.au/img/15/19');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
min-height:300px;
@media #{$medium-up} {
min-height: 300px;};
@media #{$large-up} {
min-height: 485px;};
 }
.MES20 {
background-color: var(--clr-9553);
& > .underlay, & > .inner-overlay { opacity: 0.5 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://roadandtrack.com.au/img/15/19');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
min-height:300px;
@media #{$medium-up} {
min-height: 300px;};
@media #{$large-up} {
min-height: 485px;};
h1.MEC20, h2.MEC20, h3.MEC20, h4.MEC20, h5.MEC20, h6.MEC20 { color: #ffffff;
 }
 }
cite.MEC20{
color: #ffffff;
}
/* Workshop Service:21 */
.MES21 {
background-color: var(--clr-9557);
& > .underlay, & > .inner-overlay { opacity: 0.5 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://roadandtrack.com.au/img/16/20');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
min-height:300px;
@media #{$medium-up} {
min-height: 300px;};
@media #{$large-up} {
min-height: 485px;};
 }
.MES21 {
background-color: var(--clr-9557);
& > .underlay, & > .inner-overlay { opacity: 0.5 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://roadandtrack.com.au/img/16/20');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
min-height:300px;
@media #{$medium-up} {
min-height: 300px;};
@media #{$large-up} {
min-height: 485px;};
h1.MEC21, h2.MEC21, h3.MEC21, h4.MEC21, h5.MEC21, h6.MEC21 { color: #ffffff;
 }
 }
cite.MEC21{
color: #ffffff;
}
/* Footer Panel:22 */
.MES22 {
background-color: var(--clr-9553);
color: var(--clr-9554);
@media #{$large-up} {
min-height: 135px;};
 }
.MES22 {
background-color: var(--clr-9553);
color: var(--clr-9554);
@media #{$large-up} {
min-height: 135px;};
h1.MEC22, h2.MEC22, h3.MEC22, h4.MEC22, h5.MEC22, h6.MEC22 { color: var(--clr-9554);
 }
 }
a.MEC22 { color: var(--clr-9554);
&:hover { color: var(--clr-9552);}
 }
cite.MEC22{
color: var(--clr-9554);
}
/* Top Header:23 */
.MES23 {
background-color: #ffffff;
 }
.MES23 {
background-color: #ffffff;
 }
/* Header Style:24 */
.MES24 {
background-color: #ffffff;
@media #{$medium-up} {
border-width: 0 0 4px 0;
};
border-style: solid;
border-color: transparent transparent var(--clr-9556) transparent;
 }
.MES24 {
background-color: #ffffff;
@media #{$medium-up} {
border-width: 0 0 4px 0;
};
border-style: solid;
border-color: transparent transparent var(--clr-9556) transparent;
 }
/* Back Button:25 */
.MES25 {
background-color: var(--clr-9558);
&:hover {background-color: var(--clr-9552);}
color: var(--clr-9553);
 }
/* Prod Label:26 */
.MES26 {
background-color: #ffffff;
color: var(--clr-9551);
 }
.MES26 {
background-color: #ffffff;
color: var(--clr-9551);
 }
a.MEC26 { color: var(--clr-9551);
 }
cite.MEC26{
color: var(--clr-9551);
}
/* News Label:27 */
.MES27 {
background-color: #ffffff;
color: var(--clr-9551);
border-width: 0 1px;
border-style: solid;
border-color: var(--clr-9555);
 }
.MES27 {
background-color: #ffffff;
color: var(--clr-9551);
border-width: 0 1px;
border-style: solid;
border-color: var(--clr-9555);
 }
a.MEC27 { color: var(--clr-9551);
 }
cite.MEC27{
color: var(--clr-9551);
}
/* Resp Menu:28 */
nav.responsive-menu {
.menu-item.MEC28{background-color: var(--clr-9554);}
& .menu-item.MEC28, & .menu-item.MEC28 > div.menu-item-wrap{ & > a.MEC28, & > i{color: #ffffff;
font-size: 21px;
text-align: center;
}
  }
& .menu-item.MEC28 { border:0;

 border-bottom-width: 1px; }
& .menu-item > .menu-item-wrap {padding: 4px 16px;}

& .sub-menu{ .sub-menu {}}

 }
/* Menu style:30 */
nav.me-Menu.MES30 {
& .menu-item.MEC30, & .menu-item.MEC30 > div.MEC30{ & > a.MEC30{color: var(--clr-9554);
}
 &:hover > a.MEC30{color: #ffffff;
}
 }
&.horizontal > .menu-item.MEC30 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC30 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC30 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC30 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 0 10px;}

& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Buy My Car:31 */
.MES31 {
background-color: var(--clr-9552);
&:hover {background-color: var(--clr-9554);}
color: #ffffff;
&:hover { color: var(--clr-9557);}
border-radius: 8px;
padding: 5px 10px;

border-width: 1px;
border-style: solid;
border-color: var(--clr-9553);
 }
/* Black:32 */
.MES32 {
 }
/* :33 */
.MES33 {
& .slider-arrow {color: var(--clr-9559);
font-size: 100px;
@media #{$medium-up} {
font-size: 100px;
};
@media #{$large-up} {
font-size: 100px;
};
} }
/* Buy My Carr:34 */
.MES34 {
background-color: var(--clr-9553);
& > .underlay, & > .inner-overlay { opacity: 0.88 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://roadandtrack.com.au/img/15/19');
background-position: top center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
min-height:300px;
@media #{$medium-up} {
min-height: 300px;};
@media #{$large-up} {
min-height: 485px;};
 }
.MES34 {
background-color: var(--clr-9553);
& > .underlay, & > .inner-overlay { opacity: 0.88 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://roadandtrack.com.au/img/15/19');
background-position: top center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
min-height:300px;
@media #{$medium-up} {
min-height: 300px;};
@media #{$large-up} {
min-height: 485px;};
h1.MEC34, h2.MEC34, h3.MEC34, h4.MEC34, h5.MEC34, h6.MEC34 { color: #ffffff;
 }
 }
cite.MEC34{
color: #ffffff;
}
/* Gold panel:35 */
.MES35 {
background-color: var(--clr-9554);
color: #ffffff;
font-size: 16.94px;
@media #{$medium-up} {
font-size: 19.36px;
};
@media #{$large-up} {
font-size: 22.4px;
};
 }
.MES35 {
background-color: var(--clr-9554);
color: #ffffff;
font-size: 16.94px;
@media #{$medium-up} {
font-size: 19.36px;
};
@media #{$large-up} {
font-size: 22.4px;
};
h1.MEC35, h2.MEC35, h3.MEC35, h4.MEC35, h5.MEC35, h6.MEC35 { color: #ffffff;
 }
h1.MEC35 { font-size: 30px; }
h1.MEC35 { @media #{$large-up} { font-size: 52.5px; }; }
h2.MEC35 { font-size: 26.4px; }
h2.MEC35 { @media #{$large-up} { font-size: 45px; }; }
h3.MEC35 { font-size: 24px; }
h3.MEC35 { @media #{$large-up} { font-size: 33px; }; }
h4.MEC35 { font-size: 21.6px; }
h4.MEC35 { @media #{$large-up} { font-size: 37.5px; }; }
h5.MEC35 { font-size: 20.4px; }
h5.MEC35 { @media #{$large-up} { font-size: 30px; }; }
h6.MEC35 { font-size: 19.2px; }
h6.MEC35 { @media #{$large-up} { font-size: 24px; }; }
 }
.MEC35 li {color: #ffffff;}
.MEC35 li { 
font-size: 16.8px;

@media #{$large-up} {
font-size: 19.6px;

}
 }
cite.MEC35{
color: #ffffff;
font-size: 16.94px;
@media #{$medium-up} {
font-size: 19.36px;
};
@media #{$large-up} {
font-size: 22.4px;
};
}
/* FAQ's:36 */
details.MES36 {
& > summary{background-color: var(--clr-9556);
}
& > summary{padding: 15px;}

 }
/* Copyright text:37 */
.MES37 {
font-size: 11.2px;
@media #{$medium-up} {
font-size: 12.8px;
};
 }
.MES37 {
font-size: 11.2px;
@media #{$medium-up} {
font-size: 12.8px;
};
 }
cite.MEC37{
font-size: 11.2px;
@media #{$medium-up} {
font-size: 12.8px;
};
}
/* Email panel padding:38 */
.MES38 {
padding: 15px;

 }
.MES38 {
padding: 15px;

 }
/* Email Panel black white text:39 */
.MES39 {
background-color: var(--clr-9557);
color: #ffffff;
font-size: 9.8px;
@media #{$medium-up} {
font-size: 11.2px;
};
 }
.MES39 {
background-color: var(--clr-9557);
color: #ffffff;
font-size: 9.8px;
@media #{$medium-up} {
font-size: 11.2px;
};
h1.MEC39, h2.MEC39, h3.MEC39, h4.MEC39, h5.MEC39, h6.MEC39 { color: #ffffff;
 }
h1.MEC39 { font-size: 17.5px; }
h1.MEC39 { @media #{$medium-up} { font-size: 21.3px; }; }
h1.MEC39 { @media #{$large-up} { font-size: 24.5px; }; }
h2.MEC39 { font-size: 15.4px; }
h2.MEC39 { @media #{$medium-up} { font-size: 17.75px; }; }
h2.MEC39 { @media #{$large-up} { font-size: 21px; }; }
h3.MEC39 { font-size: 14px; }
h3.MEC39 { @media #{$medium-up} { font-size: 14.2px; }; }
h3.MEC39 { @media #{$large-up} { font-size: 15.4px; }; }
h4.MEC39 { font-size: 12.6px; }
h4.MEC39 { @media #{$medium-up} { font-size: 12.78px; }; }
h4.MEC39 { @media #{$large-up} { font-size: 17.5px; }; }
h5.MEC39 { font-size: 11.9px; }
h5.MEC39 { @media #{$medium-up} { font-size: 12.07px; }; }
h5.MEC39 { @media #{$large-up} { font-size: 14px; }; }
h6.MEC39 { font-size: 11.2px; }
h6.MEC39 { @media #{$medium-up} { font-size: 11.36px; }; }
h6.MEC39 { @media #{$large-up} { font-size: 11.2px; }; }
 }
a.MEC39 { color: #ffffff;
 }
cite.MEC39{
color: #ffffff;
font-size: 9.8px;
@media #{$medium-up} {
font-size: 11.2px;
};
}
/* Video shape:41 */
.MES41 {
background-color: var(--clr-9557);
border-radius: 20px;
-webkit-transform: translateZ(0);
overflow: hidden;
 }
.MES41 {
background-color: var(--clr-9557);
border-radius: 20px;
-webkit-transform: translateZ(0);
overflow: hidden;
 }
/* :42 */
.MES42 {
background-color: #ffffff92;
padding: 20px;

@media #{$large-up} {
padding: 30px;

}
 }
.MES42 {
background-color: #ffffff92;
padding: 20px;

@media #{$large-up} {
padding: 30px;

}
 }
/* Button Black:43 */
.MES43 {
background-color: var(--clr-9554);
&:hover {background-color: var(--clr-9552);}
color: #ffffff;
&:hover { color: #ffffff;}
font-size: 15.4px;
@media #{$medium-up} {
font-size: 19.04px;
};
@media #{$large-up} {
font-size: 20.96px;
};
border-radius: 8px;
padding: 10px 15px;

border-width: 1px;
border-style: solid;
border-color: var(--clr-9553);
 }
/* Black Transparent:44 */
.MES44 {
background-color: #00000059;
 }
.MES44 {
background-color: #00000059;
 }
/* Page Accordion:45 */
details.MES45 {
 }
